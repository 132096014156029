@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap");

@font-face {
  font-family: "Thicccboi";
  src: url("./assets/fonts/Webfont/THICCCBOI-Thin.woff2") format("woff2");
  font-weight: 100; /* Thin */
  font-style: normal;
}

@font-face {
  font-family: "Thicccboi";
  src: url("./assets/fonts/Webfont/THICCCBOI-Light.woff2") format("woff2");
  font-weight: 300; /* Light */
  font-style: normal;
}

@font-face {
  font-family: "Thicccboi";
  src: url("./assets/fonts/Webfont/THICCCBOI-Regular.woff2") format("woff2");
  font-weight: 400; /* Regular */
  font-style: normal;
}

@font-face {
  font-family: "Thicccboi";
  src: url("./assets/fonts/Webfont/THICCCBOI-Medium.woff2") format("woff2");
  font-weight: 500; /* Medium */
  font-style: normal;
}

@font-face {
  font-family: "Thicccboi";
  src: url("./assets/fonts/Webfont/THICCCBOI-SemiBold.woff2") format("woff2");
  font-weight: 600; /* SemiBold */
  font-style: normal;
}

@font-face {
  font-family: "Thicccboi";
  src: url("./assets/fonts/Webfont/THICCCBOI-Bold.woff2") format("woff2");
  font-weight: 700; /* Bold */
  font-style: normal;
}

@font-face {
  font-family: "Thicccboi";
  src: url("./assets/fonts/Webfont/THICCCBOI-ExtraBold.woff2") format("woff2");
  font-weight: 800; /* ExtraBold */
  font-style: normal;
}

@font-face {
  font-family: "Thicccboi";
  src: url("./assets/fonts/Webfont/THICCCBOI-Black.woff2") format("woff2");
  font-weight: 900; /* Black */
  font-style: normal;
}

@font-face {
  font-family: "Thicccboi";
  src: url("./assets/fonts/Webfont/THICCCBOI-ThicccAF.woff2") format("woff2");
  font-weight: 950; /* ThicccAF */
  font-style: normal;
}


body {
  padding: 0;
  margin: 0;
  font-family: "Outfit", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
